import React from 'react';
import { LogoDigixLaranja } from '../../estilos/logos'

const Footer = () => {
  return (
    <footer class="rodape-sistema">
      <a href="https://www.digix.com.br/" className="rodape-sistema__texto"
      style={{"textDecoration": "none"}}>www.digix.com.br</a>
      <a href="https://www.digix.com.br/">
        <img className="rodape-sistema__logo" src={LogoDigixLaranja} alt="Digix"/>
      </a>
    </footer>  
  );
};

export default Footer;