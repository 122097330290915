import { useLocation } from "react-router";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const obterParametrosDaUrl = (parametros) => {
  let parametrosTratados = parametros.replace('?', '').split('&').map(e => e.split('='));
  
  let parametrosComoObjeto = {};
  parametrosTratados.forEach(p => parametrosComoObjeto = {
     ...parametrosComoObjeto, [p[0]]: p[1] 
    }
  );
  return parametrosComoObjeto;
}

export { useQuery, obterParametrosDaUrl };