import React, { Component } from 'react';
import { obterTermosAtivosPorProduto } from '../../servicos/termosApi';
import { obterParametrosDaUrl } from '../../helpers/parametrosDeUrl';
import { Header, Footer } from '../../componentes'
import EstadoVazio from '@bit/digix.digixui.estado-vazio';
import Carregamento from '@bit/digix.digixui.carregamento';

class PoliticaDePrivacidade extends Component {

  constructor(props) {
    super(props);
    this.state = {
      deveExibirCarregamento: true,
      politicasDePrivacidadeDoProduto: [],
      estadoVazio: {
        mensagem: 'Políticas de privacidade',
        descricao: "No momento não existem políticas de privacidade para esse produto.",
        icone: 'far fa-file-alt',
        cor: ''
      }
    };
  }

  componentDidMount() {
    let { produto } = obterParametrosDaUrl(this.props.location.search);

    obterTermosAtivosPorProduto(produto, 'PoliticaDePrivacidade')
      .then((politicasDePrivacidadeDoProduto) => {
        this.setState({
          produto, politicasDePrivacidadeDoProduto,
          deveExibirCarregamento: false
        });
      }).catch(() => {
        this.setState({
          estadoVazio: {
            mensagem: 'Identificamos um erro',
            descricao: 'Não foi possível obter as políticas de privacidade do produto por conta de um erro interno. Recarregue a página (F5) e caso o problema persista, entre em contato com o suporte',
            icone: 'far fa-times-circle',
            cor: 'erro'
          },
          deveExibirCarregamento: false
        });
      });
  }

  render() {
    let { deveExibirCarregamento, politicasDePrivacidadeDoProduto, estadoVazio } = this.state;

    return (
      <React.Fragment>
        <Header />
        {deveExibirCarregamento &&
          <Carregamento
            tamanho='grande'
            altura='grande'
            telaCheia={true} />}
        {politicasDePrivacidadeDoProduto[0] ?
          <React.Fragment>
            {politicasDePrivacidadeDoProduto.map(politica => <div dangerouslySetInnerHTML={{ __html: politica.conteudo }}></div>)}
          </React.Fragment>
          : !deveExibirCarregamento &&
          <EstadoVazio
            icone={estadoVazio.icone}
            mensagem={estadoVazio.mensagem}
            descricao={estadoVazio.descricao}
            cor={estadoVazio.cor}
            altura="medio"
            largura="medio" />}
        <Footer />
      </React.Fragment>
    );
  }
}

export default PoliticaDePrivacidade;