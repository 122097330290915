function obterNomeDoEstiloDoProduto(nomeDoProduto) {
    nomeDoProduto = nomeDoProduto || '';    
    const estilosDosProdutos = [
        'habix-inscricao-compartilhada',
        'habix',
        'cheff-escolar',
        'horus',
        'nexxus',
        'papel-zero',
        'saiems',
        'humani',
        'vistta',
        'digix'
    ];

    let nomeDoEstiloDoProduto;

    for(let nomeDoEstilo of estilosDosProdutos){
        if(nomeDoProduto.toLowerCase().includes(nomeDoEstilo.toString())){
            nomeDoEstiloDoProduto = nomeDoEstilo;
            break;
        }
    }

    return nomeDoEstiloDoProduto;
}

export default obterNomeDoEstiloDoProduto;