import { requisicaoWeb } from './axios';

const aceitarTermos = async (loginDoUsuario, hashDoLoginDoUsuario, nomeDoProtudo, hashDoNomeDoProduto, idsDosTermos) => {
    const dados = {
        "login": loginDoUsuario,
        "hashLogin": hashDoLoginDoUsuario,
        "produto": nomeDoProtudo,
        "hashProduto": hashDoNomeDoProduto,
        "termos": idsDosTermos
    }
    const resposta = await requisicaoWeb.post('/api/usuario/aceitacaoDeTermos', dados);
    return resposta.data;
}

const obterTermosNaoAceitos = async (loginDoUsuario, nomeDoProtudo) => {
    const dados = {
        "login": loginDoUsuario,
        "produto": nomeDoProtudo,
    }
    const resposta = await requisicaoWeb.get('/api/usuario/termosNaoAceitos', {params: { ...dados}});
    return resposta.data;
}

const obterTermosAtivosPorProduto = async (nomeDoProduto, tipoDoTermo) => {
    const dados = {
        "produto": nomeDoProduto,
        "tipo": tipoDoTermo
    }
    const resposta = await requisicaoWeb.get('/api/termos', {params: { ...dados}});
    return resposta.data;
}

export { aceitarTermos, obterTermosNaoAceitos, obterTermosAtivosPorProduto };