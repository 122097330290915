import React from 'react';
import ReactDOM from 'react-dom'
import { Router, Route, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { AceitacaoDeTermos, RotaNaoEncontrada, PoliticaDePrivacidade, VisualizacaoDeTermos } from './telas'
import './estilos/estilos.scss';
import ContainerDeMensagensFlutuantes from '@bit/digix.digixui.mensagens-flutuantes';

const history = createBrowserHistory({ basename: window._environment.URL_BASE });

ReactDOM.render(
  <Router history={history}>
    <Switch>
      <Route exact path="/termos/visualizacao" component={VisualizacaoDeTermos}></Route>
      <Route exact path="/termos" component={AceitacaoDeTermos}></Route>
      <Route exact path="/politicasDePrivacidade" component={PoliticaDePrivacidade}></Route>
      <Route component={RotaNaoEncontrada} />
    </Switch>
    <ContainerDeMensagensFlutuantes />
  </Router>,
  document.getElementById('react-app')
)