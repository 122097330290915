import {
    LogoHumani, LogoCheffEscolar, LogoHabix, LogoDigixCiano,
    LogoHorus, LogoNexxus, LogoPapelZero, LogoSaiems, LogoDigixBranco, LogoDigixLaranja,
    LogoVistta
} from '../estilos/logos';

function obterLogoDaLista(lista, logoParaObter){
    let logoDoProduto;

    for (let produto in lista) {
        if (lista.hasOwnProperty(produto)
            && logoParaObter.toLowerCase().includes(produto.toString())) {
            logoDoProduto = lista[produto];
            break;
        }
    }

    return !logoDoProduto ? null : logoDoProduto;
}

function obterLogoDoProdutoPorNome(nomeDoProduto) {
    nomeDoProduto = nomeDoProduto || '';
    const logosDosProdutos = {
        'habix': LogoHabix,
        'cheff-escolar': LogoCheffEscolar,
        'horus': LogoHorus,
        'nexxus': LogoNexxus,
        'papel-zero': LogoPapelZero,
        'saiems': LogoSaiems,
        'humani': LogoHumani,
        'digix': LogoDigixCiano,
        'vistta': LogoVistta
    };

    let logoDoProduto = obterLogoDaLista(logosDosProdutos, nomeDoProduto);

    return logoDoProduto;
}

function alterarFavIconPorNomeDoEstiloDoProduto(nomeDoEstiloDoProduto) {
    let favIcon = document.querySelector('link[rel="shortcut icon"]');

    if (!favIcon) {
        favIcon = document.createElement('link');
        favIcon.id = 'favicon';
        favIcon.rel = 'shortcut icon';
        document.head.appendChild(favIcon);
    }

    favIcon.href = `./favicons/${nomeDoEstiloDoProduto}.png`;
}

export { obterLogoDoProdutoPorNome, alterarFavIconPorNomeDoEstiloDoProduto };