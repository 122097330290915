import React from 'react';
import { useQuery } from '../../helpers/parametrosDeUrl';
import obterNomeDoEstiloDoProduto from '../../helpers/gerenciadorDeEstilos';
import { obterLogoDoProdutoPorNome,
   alterarFavIconPorNomeDoEstiloDoProduto } from '../../helpers/gerenciadorDeLogos';

const Header = () => { 
  let query = useQuery();
  let nomeDoProduto = query.get('produto') || '';
  let nomeDoEstiloDoProduto = obterNomeDoEstiloDoProduto(nomeDoProduto);
  let logoDoProduto = obterLogoDoProdutoPorNome(nomeDoProduto);
  alterarFavIconPorNomeDoEstiloDoProduto(nomeDoEstiloDoProduto)
  document.body.classList.add('pagina');
  document.body.classList.add(`tema-${nomeDoEstiloDoProduto}`);

  return (
    <header className="cabecalho-sistema u-margem-inferior-grande">
      <div className="u-termo-de-aceite-largura-maxima">
        <div className="cabecalho-sistema__container-primario">
          {logoDoProduto && <img className="cabecalho-sistema__logo u-sem-margem" src={logoDoProduto} alt="Sistema" />}
        </div>
      </div>
    </header>
  );
};

export default Header;