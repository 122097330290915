import React, { Component } from 'react';
import { aceitarTermos, obterTermosNaoAceitos } from '../../servicos/termosApi';
import { obterParametrosDaUrl } from '../../helpers/parametrosDeUrl';
import EstadoVazio from '@bit/digix.digixui.estado-vazio';
import Carregamento from '@bit/digix.digixui.carregamento';
import { Header } from '../../componentes'
import { GerenciadorDeMensagem } from '@bit/digix.digixui.mensagens-flutuantes';

class AceitacaoDeTermos extends Component {

  constructor(props) {
    super(props);
    this.state = {
      usuarioAceitouTermos: false,
      deveExibirCarregamento: true,
      termosNaoAceitos: [],
      estadoVazio: {
        mensagem: 'Termos para aceitar',
        descricao: "No momento não existem termos pendentes para aceitar. Retorne para a página do produto e tente realizar o login.",
        icone: 'far fa-file-alt',
        cor: ''
      }
    };
  }

  componentDidMount() {
    let { login, hashLogin, produto, hashProduto,
      returnUrl } = obterParametrosDaUrl(this.props.location.search);

    obterTermosNaoAceitos(login, produto)
      .then((termosNaoAceitos) => {
        this.setState({
          login, hashLogin, produto, hashProduto, termosNaoAceitos, returnUrl,
          deveExibirCarregamento: false
        });
      }).catch(() => {
        this.setState({
          estadoVazio: {
            mensagem: 'Identificamos um erro',
            descricao: 'Não foi possível obter os termos pendentes de aceitação por conta de um erro interno. Recarregue a página (F5) e caso o problema persista, entre em contato com o suporte',
            icone: 'far fa-times-circle',
            cor: 'erro'
          },
          deveExibirCarregamento: false
        });
      });
  }

  marcarCaixaDeConfirmacao = (event) => {
    const caixaMarcada = event.target.checked;

    this.setState({ usuarioAceitouTermos: caixaMarcada })
  }

  aceitarTermos = () => {
    this.setState({ deveExibirCarregamento: true });
    let { login, hashLogin, produto, hashProduto, termosNaoAceitos, returnUrl } = this.state;
    let idsDosTermos = termosNaoAceitos.map((t) => t.id);
    let urlDecodificada = decodeURIComponent(returnUrl);
    if(!urlDecodificada.includes('https://') && !urlDecodificada.includes('http://')){
      urlDecodificada = `http://${urlDecodificada}`;
    }
    aceitarTermos(login, hashLogin, produto, hashProduto, idsDosTermos).then(() => {
      window.location.replace(urlDecodificada);
    })
      .catch(() => {
        this.setState({ deveExibirCarregamento: false });
        GerenciadorDeMensagem.criarMensagem({
          texto: 'Não foi possível aceitar os termos. Caso o problema persista, entre em contato com o suporte',
          titulo: 'Ops...',
          tipo: 'erro'
        })
      });
  }

  render() {
    let { deveExibirCarregamento, termosNaoAceitos, usuarioAceitouTermos, estadoVazio } = this.state;

    return (
      <React.Fragment>
        <Header />
        {deveExibirCarregamento &&
          <Carregamento
            tamanho='grande'
            altura='grande'
            telaCheia={true} />}
        {termosNaoAceitos[0] ?
          <React.Fragment>
            {termosNaoAceitos.map(termo => <div dangerouslySetInnerHTML={{ __html: termo.conteudo }}></div>)}
            <footer className="rodape-termo-de-aceite">
              <form className="formulario u-termo-de-aceite-largura-maxima">
                <div className="grade">
                  <div className="grade__linha">
                    <div className="grade__coluna grade__coluna_alinhar-vertical-centro">
                      <label className="formulario__checkbox-container u-sem-margem">
                        <input type="checkbox" id="checkboxAceite" onChange={this.marcarCaixaDeConfirmacao} />
                        Declaro que li e aceito as condições expostas nos textos acima
                      </label>
                    </div>

                    <div className="grade__coluna grade__coluna_estreita">
                      <button disabled={!usuarioAceitouTermos} className="botao botao_cor-primaria"
                        onClick={this.aceitarTermos} type='button'>
                        Aceitar e continuar
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </footer>
          </React.Fragment>
          : !deveExibirCarregamento &&
          <EstadoVazio
            icone={estadoVazio.icone}
            mensagem={estadoVazio.mensagem}
            descricao={estadoVazio.descricao}
            cor={estadoVazio.cor}
            altura="medio"
            largura="medio" />}
      </React.Fragment>);
  }
}

export default AceitacaoDeTermos;